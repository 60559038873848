import { observable, flow } from 'mobx';
import { getEventRegistrations, updateEventRegistrations } from '../services/api';
import AgreementStore from './AgreementStore';
import { ToastStore } from '@stores';

class EventModel implements IEventStore {
  @observable state: StoreState = 'Idle';

  @observable eventRegistrations?: IEventRegistration[] = undefined;

  getEventRegistrations = flow(function* (this: EventModel) {
    const agreementId = AgreementStore.selectedAgreementId;
    if (!agreementId) {
      console.error('Agreement ID missing');
      return;
    }
    this.state = 'Loading';

    try {
      const response = yield getEventRegistrations(String(agreementId));

      this.eventRegistrations = response.data?.events;
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  updateEventRegistrations = flow(function* (this: EventModel, eventId: string, data: UpdateEventRegistrationsDTO, cb: any) {
    const agreementId = AgreementStore.selectedAgreementId;
    if (!agreementId || !eventId) {
      console.error('Agreement ID or event ID missing');
      return;
    }
    this.state = 'Loading';

    try {
      const response = yield updateEventRegistrations(String(agreementId), eventId, data);
      this.getEventRegistrations();
      this.state = 'Success';
      cb();
      ToastStore.showSuccess('pages.events.updateSuccess', true);
    } catch (error) {
      cb();
      ToastStore.showError('pages.events.updatError', true);
      this.state = 'Error';
    }
  }).bind(this);
}

const EventStore = new EventModel();
export default EventStore;
