/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-named-as-default */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import errorHandler from './ErrorService';
import { CurrentUserStore, ToastStore } from '@stores';
import { TOKEN } from '../stores/AuthStore';
import { getResidenceTime } from '../utils/myInformationUtils';

export const API_PATHS = Object.freeze({
  activateUser: '/user/activate/{activationCode}',
  agreementParties: '/customers/agreements/{ID}/agreement_parties',
  agreements: '/customers/agreements',
  authOpCallback: '/auth/opCallback',
  authOpCallbackPassword: '/auth/opCallbackPassword',
  authUrl: '/auth/getAuthUrl',
  building: '/customers/agreements/{ID}/estate/buildings',
  charges: '/customers/agreements/{ID}/charges',
  checklist: '/checklist?lang={LANG}',
  contacts: '/customers/contacts',
  createUser: '/auth/createValidatedUser',
  currentUser: '/customers/users/current',
  currentUserPassword: '/customers/users/current/password',
  currentUserUsername: '/customers/users/current/username',
  deposits: '/customers/agreements/{ID}/deposits',
  document: '/customers/agreements/{ID}/documents/files/{documentId}',
  agreementDocument: '/customers/agreements/{ID}/agreement/files/{documentId}',
  documents: '/customers/agreements/{ID}/documents/{category}',
  tenantDocuments: '/customers/agreements/{ID}/agreement',
  exceptionNotices: '/news/exceptionNotices',
  faq: '/faq/lists/slug/{slug}?lang={LANG}',
  faultReports: '/customers/agreements/{ID}/faultreports',
  faultReportAttachment: '/customers/agreements/{ID}/faultreports/attachments',
  feedback: '/feedback',
  login: '/auth/login',
  moveInReport: '/customers/agreements/{ID}/movinginform',
  news: '/news',
  parkingLots: '/customers/agreements/{ID}/estate/parkinglots',
  currentUserParkingLots: '/customers/parkinglots',
  payments: '/customers/agreements/{ID}/payments',
  profilePic: '/customers/users/current/profilepicture',
  resident: '/customers/agreements/{ID}/residents/{partyId}',
  residents: '/customers/agreements/{ID}/residents',
  rightOfOccupancyPayments: '/customers/agreements/{ID}/right_of_occupancy_payments',
  setWizardCompleted: '/user/finishwizard',
  testPost: '/testPost',
  userDetails: '/customers/users/current',
  messages: {
    unreadCount: '/customers/messages/unread_count',
    threads: '/customers/messages/threads',
    markThreadRead: '/customers/messages/threads/{ID}/read',
    messageAttachment: '/customers/messages/attachments/{ID}',
    sendMessageAttachment: '/customers/messages/attachments',
    postMessage: '/customers/messages',
    postThreadMessage: '/customers/messages/{ID}',
    pollMessages: '/customers/messages/new/{LAST_TS}',
  },
  changePassword: '/auth/change-password',
  updatePassword: '/auth/reset-password',
  changeEmail: '/customers/changeEmail',
  pushNotificationSubscription: '/customers/subscriptions',
  pushNotificationTest: '/customers/pushtest/{userId}/{type}',
  page: '/pageContent/slug/{ID}?lang={LANG}',
  settings: '/settings',
  eventRegistrations: '/customers/agreements/{ID}/eventregistrations',
  updateEventRegistrations: '/customers/agreements/{ID}/eventregistrations/{eventId}',
  eventTicket: '/customers/agreements/{ID}/eventregistrations/{eventId}/ticket',
});

const api = axios.create({
  baseURL: process.env.GATSBY_API_URL || process.env.REACT_API_URL,
  // baseURL: 'https://yhkodit-asukassivusto-backend.stage.geniem.io',
  timeout: 20000,
});

// Errors
api.interceptors.response.use(
  (response) => response,
  errorHandler,
);

api.interceptors.request.use((config) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const token = localStorage.getItem(TOKEN);
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Testing
export const testPost = async (data: any) => {
  const response = await api.post(`${API_PATHS.testPost}`, data);
  console.info('GREAT SUCCESS - data:', data);
  ToastStore.showSuccess(`Lähetettiin: \n\n ${JSON.stringify(data)}`);
  return response;
};

const getPath = (path: string, agreementId: number) => path.replace('{ID}', String(agreementId));

/**
 * Feedback
 */
export const postFeedback = async (feedback: IFeedback, successCallback: () => void) => {
  await api.post(API_PATHS.feedback, feedback);
  successCallback();
};

/**
 * News
 */
export const getNews = async (params: GetNewsDto) =>
  api.get(API_PATHS.news, { params });

export const getExceptionNotices = async (params: GetNewsDto) =>
  api.get(API_PATHS.exceptionNotices, { params });

export const getArticle = async (id: number) => api.get(`${API_PATHS.news}/${id}`);

/**
 * Checklist
 */
export const getChecklist = async (lang: string) => {
  const path = API_PATHS.checklist
  .replace('{LANG}', lang);
  return api.get(path);
}
/**
 * Faq
 */
export const getFaqList = async (slug: string, lang: string) => {
  const path = API_PATHS.faq
  .replace('{slug}', slug)
  .replace('{LANG}', lang);
  return api.get(path);
}

// Registration
/**
 * Fetdhes an URL to external identification service
 */
export const getAuthUrl = async (callbackUri: string) => api.post(API_PATHS.authUrl, { callbackUri });

/**
 * Creates a new user after identification
 */
export const createUser = async (formData: { email: string; password: string }) =>
  api.post(`${API_PATHS.createUser}`, formData);

/**
 * Fetches user info derived from the external identification service
 */
export const getValidatedUserInfo = async (params: string) =>
  api.get(`${API_PATHS.authOpCallback}/${params}`);


export const getPasswordResetInfo = async (params: string) => {
  const url = `${API_PATHS.authOpCallbackPassword}?${params}`;
  console.log('Making request to:', url);

  return api.get(url);
};

/**
 * Activates an user account
 */
export const activateUser = async (activationCode: string) =>
  api.get(API_PATHS.activateUser.replace('{activationCode}', activationCode));


// AUTH

/**
 * Logs user in
 */
export const login = async (formData: { email: string; password: string }) => {
  const res = await api.post(`${API_PATHS.login}`, formData);
  return res;
};

export const setWizardCompleted = (email: string) => api.post(API_PATHS.setWizardCompleted, { email });

export const changeEmail = (formData: { username: string; password: string }) =>
  api.post(API_PATHS.changeEmail, formData);


export const updatePassword = (formData: { passwordResetToken: string, password: string }) =>
  api.post(API_PATHS.updatePassword, formData);

export const changePassword = (email: string) =>
  api.post(API_PATHS.changePassword, { email });



/**
 * AGREEMENTS
 */
export const fetchAgreements = async () => {
  return api.get(API_PATHS.agreements);
};

export const fetchAgreement = async (agreementId: IAgreement['agreement_id']) => {
  const path = `${API_PATHS.agreements}/${agreementId}`;
  return api.get(path);
};

export const fetchAgreementParties = async (agreementId: IAgreement['agreement_id']) => {
  const path = getPath(API_PATHS.agreementParties, agreementId);
  try {
    const response = await api.get(path) as any;
    return response.data?.agreement_parties?.map((agreementParty: IAgreementParty) => ({ ...agreementParty, ...{ residence_time: getResidenceTime(agreementParty) } }));
  } catch (e) {
    throw e;
  }
};

export const fetchResidents = async (agreementId: IAgreement['agreement_id']) => {
  const path = getPath(API_PATHS.residents, agreementId);
  try {
    const response = await api.get(path) as any;
    return response.data?.residents?.map((resident: IResident) => ({ ...resident, ...{ residence_time: getResidenceTime(resident) } }));
  } catch (e) {
    throw e;
  }
};

export const postResident = async (agreementId: IAgreement['agreement_id'], resident: IResident) => {
  const path = getPath(API_PATHS.residents, agreementId);
  return api.post(path, resident);
};

export const putResident = async (agreementId: IAgreement['agreement_id'], partyId: IResident['party_id'], data: IResidentEssentials) => {
  const path = API_PATHS.resident
    .replace('{ID}', String(agreementId))
    .replace('{partyId}', String(partyId));

  return api.put(path, data);
}

export const removeResident = async (agreementId: IAgreement['agreement_id'], partyId: IResident['party_id']) => {
  const path = API_PATHS.resident
    .replace('{ID}', String(agreementId))
    .replace('{partyId}', String(partyId));

  return api.delete(path);
}

export const fetchCurrentUserParkingLots = async () =>
  api.get(API_PATHS.currentUserParkingLots);

/**
 * ESTATE / BUILDING
 */
export const fetchBuildings = async (agreementId: IAgreement['agreement_id']) => {
  const path = getPath(API_PATHS.building, agreementId);
  return api.get(path);
};

export const fetchParkingLots = async (agreementId: IAgreement['agreement_id']) =>
  api.get(getPath(API_PATHS.parkingLots, agreementId));

export const fetchPayments = async (agreementId: IAgreement['agreement_id']) =>
  api.get(getPath(API_PATHS.payments, agreementId));

export const fetchRightOfOccupancyPayments = async (agreementId: IAgreement['agreement_id']) =>
  api.get(getPath(API_PATHS.rightOfOccupancyPayments, agreementId));

export const fetchDeposits = async (agreementId: IAgreement['agreement_id']) => {
  const path = getPath(API_PATHS.deposits, agreementId);
  return api.get(path);
};

export const fetchCharges = async (agreementId: IAgreement['agreement_id']) => {
  const path = getPath(API_PATHS.charges, agreementId);
  return api.get(path);
};

/**
 * FAULT REPORTS
 */
export const fetchFaultReports = async (agreementId: IAgreement['agreement_id']) => {
  const path = getPath(API_PATHS.faultReports, agreementId);
  return api.get(path);
};

export const createFaultReport = async (agreementId: IAgreement['agreement_id'], faultReport: any) => {
  const path = getPath(API_PATHS.faultReports, agreementId);
  return api.post(path, faultReport)
}

export const sendFaultReportAttachment = async (file: File, agreementId: number) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const formData = new FormData();
  formData.append('file', file);
  const result = await api.post(getPath(API_PATHS.faultReportAttachment, agreementId), formData, config);
  return result;
};


/**
 * USER
 */
export const fetchCurrentUser = async () => {
  return api.get(API_PATHS.currentUser);
}

export const updateCurrentUser = async (user: IUser) => {
  return api.put(API_PATHS.currentUser, user);
}

export const getProfilePic = async () => {
  return api.get(API_PATHS.profilePic, {
    timeout: 30000,
    responseType: 'arraybuffer',
  });
};

export const removeUser = async (email: string) => {
  return api.delete(`/customers/users/${email}`);
}

export const updateProfilePic = async (pic: File) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const formData = new FormData();
  formData.append('profilepicture', pic);
  const result = await api.put(API_PATHS.profilePic, formData, config);

  CurrentUserStore.getProfilePicture();

  return result;
};

/**
 * DOCUMENTS
 */
export const fetchDocuments = async (agreementId: IAgreement['agreement_id'], category: DownloadableDocumentType) => {
  const path = API_PATHS.documents
    .replace('{ID}', String(agreementId))
    .replace('{category}', category);

  return api.get(path);
};

export const fetchTenantDocuments = async (agreementId: IAgreement['agreement_id']) => {
  const path = API_PATHS.tenantDocuments
    .replace('{ID}', String(agreementId));

    return api.get(path)
}


export const fetchDocument = async (agreementId: IAgreement['agreement_id'], _category: DownloadableDocumentType, documentId: number) => {
  const path = API_PATHS.document
    .replace('{ID}', String(agreementId))
    .replace('{documentId}', String(documentId));

  return api.get(path, {
    timeout: 30000,
    responseType: 'arraybuffer',
  });
};

export const getAgreementDocument = async (agreementId: IAgreement['agreement_id'], documentId: number) => {
  const path = API_PATHS.agreementDocument
    .replace('{ID}', String(agreementId))
    .replace('{documentId}', String(documentId));

  return api.get(path, {
    timeout: 30000,
    responseType: 'arraybuffer',
  });
};
/**
 * Contacts
 */
export const fetchContacts = async () => {
  return api.get(API_PATHS.contacts)
};

/**
 * Other forms
 */
export const postMoveInReport = async (agreementId: number, data: any) => {
  return api.post(getPath(API_PATHS.moveInReport, agreementId), data);
}

/**
 * Messages
 */
export const getMessageUnreadCount = () =>
  api.get(API_PATHS.messages.unreadCount)

export const getMessageThreads = () =>
  api.get(API_PATHS.messages.threads)

export const postMessage = (message: INewMessage, subject: string) =>
  api.post(API_PATHS.messages.postMessage, { files: message.files, content: message.content, subject });

export const postThreadMessage = (threadId: string, message: INewMessage) =>
  api.post(API_PATHS.messages.postThreadMessage.replace('{ID}', threadId), message);

export const markThreadRead = (threadId: string) =>
  api.put(API_PATHS.messages.markThreadRead.replace('{ID}', threadId));

export const getMessageAttachment = (downloadId: string) => {
  return api.get(API_PATHS.messages.messageAttachment.replace('{ID}', downloadId), {
    timeout: 30000,
    responseType: 'arraybuffer',
  });
}

export const pollMessages = async (lastTs: number) => {
  const config = {
    timeout: null,
  }
  // @ts-ignore
  return api.get(API_PATHS.messages.pollMessages.replace('{LAST_TS}', String(lastTs)), config);
};

export const sendMessageAttachment = async (pic: File) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  const formData = new FormData();
  formData.append('file', pic);
  const result = await api.post(API_PATHS.messages.sendMessageAttachment, formData, config);
  return result;
};

/**
 * Push notifications
 */

export const getPushSubscriptionInfo = async () =>
  api.get(API_PATHS.pushNotificationSubscription);


export const addPushSubscriptionInfo = async (data: any) => {
  return api.post(API_PATHS.pushNotificationSubscription, data);
}

export type PushNotificationType = 'events' | 'messages' | 'faultreports' | 'exceptions' | 'notifications';
export const testPush = (userId: string, type: PushNotificationType) => {
  const path = API_PATHS.pushNotificationTest
    .replace('{userId}', String(userId))
    .replace('{type}', type);
  return api.post(path);
};

/**
 * PAGES
 */
 export const getPage = (slug: string, lang: string) => {
  const path = API_PATHS.page
    .replace('{ID}', slug)
    .replace('{LANG}', lang);
  return api.get(path);
};


export const getSettings = () =>
  api.get(API_PATHS.settings)

/**
 *
 * Events and event registrations
 */

export const getEventRegistrations = async (agreementId: string) => {
  return api.get(API_PATHS.eventRegistrations.replace('{ID}', agreementId));
}

export const updateEventRegistrations = async (agreementId: string, eventId: string, data: UpdateEventRegistrationsDTO) => {
  const path = API_PATHS.updateEventRegistrations
    .replace('{ID}', agreementId)
    .replace('{eventId}', eventId);
  return api.post(path, data);
}

export const getEventTicket = async (agreementId: string, eventId: string) => {
  const path = API_PATHS.eventTicket
    .replace('{ID}', String(agreementId))
    .replace('{eventId}', String(eventId));

  return api.get(path, {
    timeout: 30000,
    responseType: 'arraybuffer',
  });
};
