import React from 'react';
import { MobXProviderContext } from 'mobx-react';

export { default as CommonDataStore } from './CommonDataStore';
export { default as ToastStore } from './ToastStore';
export { default as AuthStore } from './AuthStore';
export { default as FaqStore } from './FaqStore';
export { default as NewsStore } from './NewsStore';
export { default as ChecklistStore } from './ChecklistStore';
export { default as AgreementStore } from './AgreementStore';
export { default as PaymentStore } from './PaymentStore';
export { default as FaultReportStore } from './FaultReportStore';
export { default as CurrentUserStore } from './CurrentUserStore';
export { default as MessageStore } from './MessageStore';
export { default as PageStore } from './PageStore';
export { default as EventStore } from './EventStore';

export const useStores = () => {
  return React.useContext(MobXProviderContext);
};
