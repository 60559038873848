import { navigate } from "@reach/router";
import { observable, flow, action } from "mobx";
import {
  fetchCurrentUser,
  updateCurrentUser as putUser,
  getProfilePic,
  changeEmail,
} from "../services/api";
import AuthStore from "./AuthStore";
import ToastStore from "./ToastStore";
// @ts-ignore
import urlBase64ToUint8Array from "urlb64touint8array";

class CurrentUserModel {
  @observable currentUser = undefined;

  @observable state: StoreState = "Idle";

  @observable profilePicUrl?: string | null = undefined;

  getCurrentUser = flow(function* (this: CurrentUserModel) {
    this.state = "Loading";

    try {
      const response = yield fetchCurrentUser();
      if (!this.profilePicUrl) yield this.getProfilePicture();
      this.currentUser = response.data;

      // Get locale from user language and change it
      if (typeof window !== "undefined") {
        // TODO: uncomment this when ready
        //  changeLocale(getLocale(response.data.language));
      }

      this.state = "Success";
    } catch (error) {
      this.state = "Error";
      throw error;
    }
  }).bind(this);

  @action
  resetStore() {
    this.currentUser = undefined;
    this.state = "Idle";
    this.profilePicUrl = undefined;
  }

  updateCurrentUser = flow(function* (
    this: CurrentUserModel,
    updatedUser: IUser,
    submitCallback: Function
  ) {
    try {
      yield putUser(updatedUser);
      submitCallback();

      ToastStore.showSuccess("common.updateSuccess", true);
      this.getCurrentUser();
    } catch (error) {
      submitCallback(error);
      throw error;
    }
  }).bind(this);

  getProfilePicture = flow(function* (this: CurrentUserModel) {
    try {
      let url = null;
      const response = yield getProfilePic();

      if (!response.data) return;

      // YH Kodit API sends null, if there's no profile pic
      if (typeof window !== "undefined" && response.data.byteLength > 4) {
        const blob = new Blob([response.data]);
        url = window.URL.createObjectURL(blob);
      }

      this.profilePicUrl = url;

      this.state = "Success";
    } catch (error) {
      this.state = "Error";
      throw error;
    }
  }).bind(this);

  changeEmail = flow(function* (
    this: CurrentUserModel,
    { email, password },
    cb
  ) {
    try {
      yield changeEmail({
        username: email,
        password,
      });
      ToastStore.showSuccess("pages.changeEmail.changeEmailSuccess", true);
      cb();
      this.state = "Success";
      const timeout = setTimeout(() => {
        AuthStore.logout();
        navigate("/");
        clearTimeout(timeout);
      }, 3000);
    } catch (error: any) {
      let msg = "pages.changeEmail.changeEmailFailed";
      this.state = "Error";

      if (error.response.status === 409) {
        msg = "pages.changeEmail.emailExists";
      }
      if (error.response.status === 400) {
        cb();
        throw error;
      }
      ToastStore.showError(msg, true);
      cb();
      throw error;
    }
  }).bind(this);
}

const CurrentUserStore = new CurrentUserModel();
export default CurrentUserStore;
