import { observable, flow } from 'mobx';
import { getChecklist } from '../services/api';
export interface IChecklistStore {
  state: StoreState;
  checklist: IChecklistItem[];
  getChecklist: (lang: string) => void;
}

class ChecklistModel {
  @observable checklist = [] as IChecklistItem[];

  @observable state: StoreState = 'Idle';

  getChecklist = flow(function* (this: ChecklistModel, lang: string) {
    this.state = 'Loading';
    try {
      const response = yield getChecklist(lang);
      // console.log(response)
      this.checklist = response.data.items;
      // console.log(this.checklist)
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);
}

const ChecklistStore = new ChecklistModel();
export default ChecklistStore;
