/* eslint-disable prefer-destructuring */
import { observable, flow, action, computed } from 'mobx';
import {
  fetchAgreements, fetchAgreement, fetchAgreementParties, fetchResidents,
  postResident, putResident, fetchDocuments, removeResident, fetchTenantDocuments,
} from '../services/api';
import { fetchParkingLots, fetchCurrentUserParkingLots } from '../services/api';
import PaymentStore from './PaymentStore';
import { ToastStore } from '@stores';
import NewsStore from './NewsStore';
import FaultReportStore from './FaultReportStore';
import { storeAutoSave } from '../utils/commonUtils';

class AgreementModel implements IAgreementStore {
  constructor() {
    const omittedObservables = [
      'state',
      'agreements',
      'fullAgreements',
      'documentState',
      'residents',
      'agreementParties',
      'parkingLots',
      'tenantsDemocracyDocuments',
      'tenantsDocuments',
      'documents',
      'currentUserParkingLots',
      'currentUserInformation'
    ];
    storeAutoSave(this, 'agreements', omittedObservables);
  }

  @observable agreements = [] as IAgreementStore['agreements'];

  @observable fullAgreements = [] as IAgreementStore['fullAgreements'];

  @observable state: StoreState = 'Idle';

  @observable documentState: StoreState = 'Idle';

  @observable selectedAgreement = undefined as IAgreementStore['selectedAgreement'];

  @observable residents = [] as IAgreementStore['residents'];

  @observable agreementParties = [] as IAgreementStore['agreementParties'];

  @observable parkingLots = [] as IAgreementStore['parkingLots'];

  @observable tenantsDemocracyDocuments = [] as IAgreementStore['tenantsDemocracyDocuments'];

  @observable tenantsDocuments = [] as IAgreementStore['tenantsDocuments'];

  @observable documents = [] as IAgreementStore['documents']

  @observable currentUserInformation = undefined;

  @observable currentUserParkingLots = undefined as IAgreementStore['currentUserParkingLots'];

  findFullAgreement = (id: number) => this.fullAgreements.find((fa) => fa.agreement_id === id);



  @computed get currentParkingLots() {
    const parkingLots = this.parkingLots.find((r) => r.agreement_id === this.selectedAgreementId)?.parkingLots;
    return parkingLots;
  }

  @computed
  public get currentResidents() {
    const residents = this.residents.find((r) => r.agreement_id === this.selectedAgreementId)?.residents;
    return residents;
  }

  @computed
  public get currentAgreementParties() {
    const agreementParties = this.agreementParties.find((p) => p.agreement_id === this.selectedAgreementId)?.agreement_parties;
    return agreementParties;
  }

  @computed
  public get currentOtherAgreementParty() {
    const otherAgreementParty = this.agreementParties?.find((agreementPartyObj) => (
      agreementPartyObj.agreement_id === this.selectedAgreementId
    ))?.agreement_parties?.find((party) => !party.current_party)
    return otherAgreementParty;
  };
  /**
   * Resets things, e.g. when changing active agreement
   */
  @action reset = () => {
    // @ts-ignore
    this.fullAgreements = [];
    // @ts-ignore
    this.agreements = undefined;
    this.state = 'Idle';
    this.documentState = 'Idle';
    this.selectedAgreement = undefined;
    this.residents = [];
    this.agreementParties = [];
    this.parkingLots = [];
    this.tenantsDemocracyDocuments = [];
    this.tenantsDocuments = [];
    this.documents = []
    this.currentUserInformation = undefined;

  };


  @computed
  public get selectedAgreementId() {
    if (!this.selectedAgreement) return undefined;
    return this.selectedAgreement?.agreement_id;
  }

  @computed
  public get currentEstate() {
    return this.selectedAgreement?.estate;
  }

  @action setSelectedAgreement = async (id: IAgreement['agreement_id']) => {
    if (this.selectedAgreementId && this.selectedAgreementId !== id) {
      PaymentStore.reset();
      NewsStore.resetStore();
      FaultReportStore.resetStore();
    }

    let res;
    let selected = this.findFullAgreement(id);
    if (!selected) {
      res = await this.getFullAgreement(id);
      selected = this.findFullAgreement(id);
    }

    this.selectedAgreement = selected;


    return res;
  };

  getAgreements = flow(function* (this: AgreementModel) {
    // console.log('AGREEMENTS FETCH', typeof window !== 'undefined', !localStorage.getItem('auth_token'));
    this.state = 'Loading';
    if (typeof window !== 'undefined') {
      if (!localStorage.getItem('auth_token')) {
         this.state = 'Error';
         return;
      }
    }
    try {
      // console.log('Getting agreements');
      const response = yield fetchAgreements();
      this.agreements = response.data.agreements;

      // Let's set the first agreement as selectedAgreement
      let previouslySelectedAgreement;
      if (this.selectedAgreement) {
        const found = this.agreements?.find((agreement) => agreement.agreement_id === this.selectedAgreement?.agreement_id);
        previouslySelectedAgreement = found;
      }
      const selected = previouslySelectedAgreement || this.agreements && this.agreements[0] as IAgreement;

      this.setSelectedAgreement(selected?.agreement_id!);
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
      console.warn("Agreements error: " + error);
    }
  }).bind(this);

  getFullAgreement = flow(function* (this: AgreementModel, id: IAgreement['agreement_id']) {
    // console.log('FULL AGREEMENT FETCH');
    if (typeof window !== 'undefined') {
      if (!localStorage.getItem('auth_token')) {
         this.state = 'Error';
        //  console.log('Not fetching agreements, no auth token');
         return;
      }
    }
    if (this.fullAgreements.find((agreement) => agreement.agreement_id === id)) return;
    this.state = 'Loading';

    try {
      const response = yield fetchAgreement(id);
      const fullAgreement = response.data;
      this.fullAgreements.push(fullAgreement);

      this.state = 'Success';
      return response;
    } catch (error) {
      this.state = 'Error';
      throw error;
    }
  }).bind(this);

  getAgreementParties = flow(function* (this: AgreementModel, id: IAgreement['agreement_id']) {
    if (this.agreementParties?.some((obj) => obj.agreement_id === id)) return;
    this.state = 'Loading';

    try {
      const agreementParties = yield fetchAgreementParties(id);

      this.agreementParties.push({ agreement_id: id, agreement_parties: agreementParties })
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  getResidents = flow(function* (this: AgreementModel, id: IAgreement['agreement_id']) {
    this.state = 'Loading';

    try {
      const residents = yield fetchResidents(id);
      this.residents.push({ agreement_id: id, residents })
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  addResident = flow(function* (this: AgreementModel, resident, callback: Function) {
    if (!this.selectedAgreementId) return;

    try {
      yield postResident(this.selectedAgreementId, resident);
      this.getResidents(this.selectedAgreementId);
      ToastStore.showSuccess('pages.myInformation.createResidentSuccess', true);
      callback();
    } catch (error: any) {
      callback(error);
      if (error.response && error.response.status === 409) {
        ToastStore.showError('pages.myInformation.residentsPage.addResidentConflict', true);
      }
      this.state = 'Error';
    }
  }).bind(this);

  updateResident = flow(function* (this: AgreementModel, partyId: IResident['party_id'], data: IResidentEssentials, cb: Function) {
    if (!this.selectedAgreementId) return;

    try {
      yield putResident(this.selectedAgreementId, partyId, data);
      this.getResidents(this.selectedAgreementId);
      ToastStore.showSuccess('pages.myInformation.updateResidentSuccess', true);
      cb();
    } catch (error) {
      cb(error);
      this.state = 'Error';
    }
  }).bind(this);


  removeResident = flow(function* (this: AgreementModel, partyId: IResident['party_id']) {
    if (!this.selectedAgreementId) return;

    try {
      yield removeResident(this.selectedAgreementId, partyId);
      ToastStore.showSuccess('pages.myInformation.removeResidentSuccess', true);
      this.getResidents(this.selectedAgreementId);
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  getTenantDemocracyDocuments = flow(function* (this: AgreementModel, agreementId: IAgreement['agreement_id']) {
    if (this.tenantsDemocracyDocuments?.some((obj) => obj.agreement_id === agreementId)) return;

    this.documentState = 'Loading';

    try {
      const response = yield fetchDocuments(agreementId, 'tenantdemocracy');
      this.tenantsDemocracyDocuments.push({ agreement_id: agreementId, documents: response.data.documents })
      this.documentState = 'Success';
    } catch (error) {
      this.documentState = 'Error';
    }
  }).bind(this);

  getTenantsDocuments = flow(function* (this: AgreementModel, agreementId: IAgreement['agreement_id']) {
    if (this.tenantsDocuments?.some((obj) => obj.agreement_id === agreementId)) return;

    this.documentState = 'Loading';

    try {
      const response = yield fetchDocuments(agreementId, 'tenantsdocuments');
      this.tenantsDocuments.push({ agreement_id: agreementId, documents: response.data.documents })
      this.documentState = 'Success';
    } catch (error) {
      this.documentState = 'Error';
    }
  }).bind(this);

  getDocuments = flow(function* (this: AgreementModel, agreementId: IAgreement ['agreement_id']) {
    if (this.documents?.some((obj) => obj.agreement_id === agreementId)) return;

    this.documentState = 'Loading'

    try {
      const response = yield fetchTenantDocuments(agreementId);
      this.documents?.push({agreement_id: agreementId, documents: response.data.documents})
      this.documentState = 'Success';
    } catch (error) {
      this.documentState = 'Error'
    }
  }).bind(this)

  getParkingLots = flow(function* (this: AgreementModel, agreementId: IAgreement['agreement_id']) {
    if (this.parkingLots?.some((obj) => obj.agreement_id === agreementId)) return;

    this.state = 'Loading';

    try {
      const response = yield fetchParkingLots(agreementId);
      this.state = 'Success';

      const lots = response.data.parkinglots;
      // console.log('fetching')
      if (!lots && !lots.length) return;

      const parkingLots = {
        vacant: lots.filter((lot: IParkingLot) => lot.status === 'vacant'),
        becomingVacant: lots.filter((lot: IParkingLot) => lot.status === 'becoming vacant'),
      };

      this.parkingLots.push({ agreement_id: agreementId, parkingLots })

    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  getCurrentUserParkingLots = flow(function* (this: AgreementModel) {
    this.state = 'Loading';

    try {
      const response = yield fetchCurrentUserParkingLots();
      this.state = 'Success';

      const lots = response.data.parkinglots;

      if (!lots && !lots.length) return;

      this.currentUserParkingLots = lots;
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);
}

const AgreementStore = new AgreementModel();
export default AgreementStore;
