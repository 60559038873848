import "typeface-roboto";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import wrapWithProvider from "./wrap-with-provider.tsx";
export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = ({ location }) => {
  // checkLanguage();
  scrollToAnchor(location);
};

const scrollToAnchor = (location, mainNavHeight = 0) => {
  // Check for location so build does not fail
  setTimeout(() => {
    if (location && location.hash) {
      const item = document.querySelector(`${location.hash}`);
      if(item) {
        const itemTop = item.offsetTop;
        window.scrollTo({
          top: itemTop - mainNavHeight,
          behavior: "smooth",
        });
      }
    }
  }, 500);
  return true;
}

export const onClientEntry = () => {
  // Google Consent Mode
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('consent', 'default', {
    ad_personalization: 'denied',
    ad_storage: 'denied',
    ad_user_data: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 3000,
  });
  gtag('set', 'ads_data_redaction', true);
  gtag('set', 'url_passthrough', false);

  // Load Cookiebot script
  const cookiebotScript = document.createElement('script');
  cookiebotScript.id = 'Cookiebot';
  cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
  cookiebotScript.setAttribute('data-cbid', '8c9fc99e-f3c3-4bfb-8ca9-b850eae8784a');
  cookiebotScript.async = true;
  document.head.appendChild(cookiebotScript);

  // Load Google Tag Manager script
  const gtmScript = document.createElement('script');
  gtmScript.textContent = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WHFFND96');
  `;
  gtmScript.setAttribute('data-cookieconsent', 'ignore');
  document.head.appendChild(gtmScript);
};
