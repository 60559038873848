/* eslint-disable prefer-destructuring */
import {
  observable, flow, action,
} from 'mobx';
import { fetchFaultReports, createFaultReport as postReport } from '../services/api';
import ToastStore from './ToastStore';
import AgreementStore from './AgreementStore';

class FaultReportModel {
  @observable faultReports = undefined;

  @observable state: StoreState = 'Idle';

  @action resetStore() {
    this.faultReports = undefined;
    this.state = 'Idle';
  }

  getFaultReports = flow(function* (this: FaultReportModel, agreementId: IAgreement['agreement_id']) {
    if (!agreementId) console.error('Agreement ID was not provided');

    this.state = 'Loading';
    try {
      const response = yield fetchFaultReports(agreementId!);
      this.faultReports = response.data.faultreports;
      this.state = 'Success';
    } catch (error) {
      this.faultReports = undefined;
      this.state = 'Error';
    }
  }).bind(this);

  createFaultReport = flow(function* (this: FaultReportModel, agreementId: IAgreement['agreement_id'], faultReport: any, callback: Function) {
    try {
      yield postReport(agreementId, faultReport);
      callback();
      ToastStore.showSuccess('common.formSubmitSuccess', true);
      yield this.getFaultReports(AgreementStore.selectedAgreementId as number);
    } catch (error) {
      callback(error);
      this.state = 'Error';
    }
  }).bind(this);
}

const FaultReportStore = new FaultReportModel();
export default FaultReportStore as IFaultReportStore;
