import moment from 'moment';
import { toJS } from 'mobx';

export const getFormattedDate = (date: string) => date ? moment(date).format('D.M.YYYY') : '';

export const getResidenceTime = (resident: IAgreementParty | IResident) => {
  if (!resident) return '';
  return `${getFormattedDate(resident.start_date)} — ${getFormattedDate(resident.end_date)}`;
}

export const getFaultReportStateColor = (state: IFaultReport['state']) => {
  switch (state) {
    case 'Käsitelty':
      return 'statusGreen';
    default:
      return 'statusYellow';
  }
};

export const getFaultReportState = (state: IFaultReport['state']) => {
  switch (state) {
    case 'Käsitelty':
      return 'processed';
    case 'Vastaanotettu':
      return 'received';
    case 'Uusi':
    default:
      return 'new';
  }
};

type Direction = 'fromYh' | 'toYh';
export const getNotificationValues = (formValues: any, direction: Direction) => {
  if (direction === 'toYh') {
    const addValues = (id: string) => ({ channel: id, notification_type: formValues.notifications[id] });
    const newNotifications = [];

    if (formValues.notifications.push) newNotifications.push(addValues('push'))
    if (formValues.notifications.sms) newNotifications.push(addValues('sms'))
    if (formValues.notifications.email) newNotifications.push(addValues('email'))

    return newNotifications;
  } else if (direction === 'fromYh') {
    const notifications = {} as any;
    formValues.notifications && formValues.notifications.map((group: UserNotificationOption) => {
      notifications[group.channel] = group.notification_type;
    });

    return notifications;
  }
}

export const getAgreementStatus = (startDate?: string, endDate?: string | null, terminated?: string | null): AgreementStatus => {
  const today = new Date();
  if (endDate && (moment(endDate).isSame(today) || moment(endDate).isBefore(today))) return 'ended';
  if (moment(today).isBefore(startDate)) return 'beginning';
  if (!endDate && terminated) return 'terminated';
  return 'active';
};
