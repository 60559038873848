import {
  action,
  flow,
  observable,
} from 'mobx';
import io from 'socket.io-client';
import { getArticle, getExceptionNotices, getNews } from '../services/api';
import AgreementStore from './AgreementStore';
class NewsModel {
  @observable allNews = {} as INewsStore['allNews'];

  @observable exceptionNotices = null as unknown as INewsStore['exceptionNotices'];

  @observable latest = null as unknown as INewsStore['latest'];

  @observable state: StoreState = 'Idle';

  @observable socketConnected: boolean = false;

  @observable socket: any;

  wsRetryCount = 0;


  @action connectSocket = () => {
    if (!this.socket) {
      // console.log(`CONNECTING TO ${process.env.GATSBY_API_URL}/news`)
      this.socket = io(`${process.env.GATSBY_API_URL}/news`, {
        transports: ['polling']
      })

      this.socket.on('connect', () => {


        // console.log('WE CONNECTED');
        this.socketConnected = true;

        this.socket.on('allNews', (data: any) => {
          // console.log('GOT WS NEWS');
          this.latest = data.items;
          this.state = 'Success';
        })

        this.socket.on('refreshNews', (data: any) => {
          this.getLatestWS();
        })

        this.getLatestWS();
      })
    }
  }

  @action resetStore = () => {
    // console.log('Resetting news')
    // @ts-ignore
    this.allNews = {};
    // @ts-ignore
    this.exceptionNotices = null;
    // @ts-ignore
    this.latest = null;
    this.socketConnected = false;
    this.socket = undefined;
    this.wsRetryCount = 0;
    this.state = 'Idle';
  }

  setSocketState = flow(function*(state: boolean) {
       // @ts-ignore
    this.socketConnected = state;
  }).bind(this);

  getLatestWS = () => {
    // console.log('Getting latest WS news');
    const estate = AgreementStore.currentEstate;
    if (!estate) {
      // console.log('We do not have an estate, lets try again in a bit, if we havent tried too many times yet.');
      this.wsRetryCount++;
      if (this.wsRetryCount < 5) {
        setTimeout(() => {
          this.getLatestWS();
        }, 1000);
      } else {
        this.socket.disconnect();
        this.socket = undefined;
        this.socketConnected = false;
        this.wsRetryCount = 0;
      }
      return;
    } 
    this.wsRetryCount = 0;
    this.state = 'Loading';
    this.socket.emit('getNews', {
      skip: 0,
      take: 5,
      estate: estate.estate_id,
      region: estate.region_id,
      owner: estate.owner_id,
      filterExceptions: true,
    });
  }


  getLatest = flow(function* (this: NewsModel) {
    const estate = AgreementStore.currentEstate;
    if (!estate) return;
    this.state = 'Loading';
    try {
      const response = yield getNews({
        skip: 0,
        take: 5,
        estate: estate.estate_id,
        region: estate.region_id,
        owner: estate.owner_id,
        filterExceptions: true,
      });
      this.latest = response.data.items;
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  getNews = flow(function* (this: NewsModel, skip: number, take: number) {
    const estate = AgreementStore.currentEstate;
    if (!estate) return;

    this.state = 'Loading';
    try {
      const response = yield getNews({
        skip,
        take,
        estate: estate.estate_id,
        region: estate.region_id,
        owner: estate.owner_id,
      });

      this.allNews = response.data;
      this.state = 'Success';
      return response;
    } catch (error) {
      this.state = 'Error';
      throw error;
    }
  }).bind(this);

  getExceptionNotices = flow(function* (this: NewsModel) {
    const estate = AgreementStore.currentEstate;

    if (!estate) return;

    this.state = 'Loading';
    try {
      const response = yield getExceptionNotices({
        estate: estate.estate_id,
        region: estate.region_id,
        owner: estate.owner_id,
      });

      this.exceptionNotices = response.data;

      this.state = 'Success';
      return response;
    } catch (error) {
      this.state = 'Error';
      throw error;
    }
  }).bind(this);

  getArticle = flow(function* (this: NewsModel, id: number) {
    this.state = 'Loading';
    try {
      this.state = 'Success';
      const res = yield getArticle(id);
      return res.data;
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);
  
}

const NewsStore = new NewsModel();
export default NewsStore;
