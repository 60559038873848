import { observable, flow } from 'mobx';
import { testPost, fetchContacts, postMoveInReport, getSettings } from '../services/api';
import AgreementStore from './AgreementStore';
import ToastStore from './ToastStore';

class CommonDataModel {
  @observable city = '';

  @observable state: StoreState = 'Idle';

  @observable contactInformation = undefined;

  @observable settings = undefined;

  testPost = flow(function* (this: CommonDataModel, postData: any, callback: Function) {
    this.state = 'Loading';
    try {
      yield testPost(postData as any);
      this.state = 'Success';
      return callback();
    } catch (error) {
      this.state = 'Error';
      return callback(error);
    }
  }).bind(this);

  getContacts = flow(function* (this: CommonDataModel) {
    this.state = 'Loading';
    try {
      const response = yield fetchContacts();
      this.contactInformation = response.data.contacts;
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  sendMoveInReport = flow(function* (this: CommonDataModel, formData: any, cb: Function) {
   const agreementId = AgreementStore.selectedAgreementId;
   if (!agreementId) return;
    this.state = 'Loading';
    try {
      const res = yield postMoveInReport(agreementId, formData);
      ToastStore.showSuccess('common.formSubmitSuccess', true);
      cb();
      this.state = 'Success';
      return res;
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  getSettings = flow(function* (this: CommonDataModel) {
     this.state = 'Loading';
     try {
       const res = yield getSettings();
       this.settings = res.data;
       this.state = 'Success';
       return res;
     } catch (error) {
       this.state = 'Error';
     }
   }).bind(this);}
const CommonDataStore = new CommonDataModel();
export default CommonDataStore;
