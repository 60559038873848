import { observable, flow, action } from 'mobx';
import AuthStore from './AuthStore';
import {
  getMessageUnreadCount,
  getMessageThreads,
  postMessage as post,
  postThreadMessage as postThreadMsg,
  markThreadRead as markRead,
  pollMessages,
} from '../services/api';
import moment from 'moment';
import { ToastStore } from '@stores';

class MessageModel {
  @observable unreadCount: IMessageStore['unreadCount'] = 0;

  @observable threads: IMessageStore['threads'] = undefined;

  @observable state: StoreState = 'Idle';

  @observable sending: boolean = false;

  @observable hasSubscribed: boolean = false;

  @action
  resetStore() {
    this.unreadCount = 0;
    this.threads = undefined;
    this.state = 'Idle';
    this.sending = false;
    this.hasSubscribed = false;
  };

  getUnreadCount = flow(function* (this: MessageModel) {
    this.state = 'Loading';

    try {
      const response = yield getMessageUnreadCount();
      this.unreadCount = response.data;
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  getThreads = flow(function* (this: MessageModel) {
    this.state = 'Loading';

    try {
      const response = yield getMessageThreads();
      // @ts-ignore
      this.threads = response.data.threads.sort((x: any, y: any) => moment(y.last_message) - moment(x.last_message));
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  /**
   * Posts a new message outside a thread
   */
  postMessage = flow(function* (this: MessageModel, message: INewMessage, subject: string) {
    this.sending = true;
    try {
      yield post(message, subject);
      ToastStore.showSuccess('pages.messages.messageSent', true);
      this.getThreads();
      this.sending = false;
    } catch (error) {
      this.sending = false;
    }
  }).bind(this);

  /**
   * Posts a message into existing thread
   */
  postThreadMessage = flow(function* (this: MessageModel, threadId: string, message: INewMessage) {
    this.sending = true;
    try {
      const response = yield postThreadMsg(threadId, message);
      ToastStore.showSuccess('pages.messages.messageSent', true);
      this.sending = false;
      const threadIndex = this.threads?.findIndex(thread => thread.thread_id === threadId);
      if (threadIndex === undefined || this.threads === undefined) return;
      this.threads[threadIndex].messages = response.data.messages;
    } catch (error) {
      this.sending = false;
    }
  }).bind(this);

  /**
   * Marks an unread thread read
   */
  markThreadRead = flow(function* (this: MessageModel, threadId: string) {
    this.state = 'Loading';
    try {
      yield markRead(threadId);
      this.state = 'Success';
      this.getThreads();
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);

  /**
   * Subscribes to new messages
   */
  subscribe = async (timestamp?: number) => {
    if (!AuthStore.isAuthenticated) return;

    this.hasSubscribed = true;

    try {
      const response = await pollMessages(timestamp || 0);
      if (!response.data) return;
      const { data: { unread, last_ts } } = response;
      if (unread > this.unreadCount) {
        await this.getThreads();
      }
      this.unreadCount = unread;
      await setTimeout(async () => {
        await this.subscribe(last_ts || 0)
      })
    } catch (error) {
      this.state = 'Error';
      const timeout = setTimeout(() => {
        this.subscribe(0);
        clearTimeout(timeout);
      }, 10000);
    }
  }
}

const MessageStore = new MessageModel();
export default MessageStore;
