import { observable, flow } from 'mobx';
import { getFaqList } from '../services/api';

class FaqModel implements IFaqStore {
  @observable faqLists = [];

  @observable state: StoreState = 'Idle';

  getFaqList = flow(function* (this: FaqModel, slug: string, lang: string) {
    if (this.faqLists.find((faqList) => faqList.slug === slug)) return;
    this.state = 'Loading';
    try {
      const response = yield getFaqList(slug, lang);
      this.faqLists.push(response.data as any);
      this.state = 'Success';
    } catch (error) {
      this.state = 'Error';
    }
  }).bind(this);
}

const FaqStore = new FaqModel();
export default FaqStore;
