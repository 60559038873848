module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"YH Kodit - asukassivusto","short_name":"YH Kodit - asukassivusto","background_color":"#0070BA","theme_color":"#0070BA","display":"standalone","icon":"/usr/src/app/static/icons/icon-144x144.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/usr/src/app/src/resources/intl","languages":["fi","en","sv"],"defaultLanguage":"fi"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
