import { observable, flow } from 'mobx';
import { getPage } from '../services/api';

class PageModel {
  @observable state: StoreState = 'Idle';

  @observable page: { [slug: string]: { [lang: string]: IPage } } = {}

  getPage = flow(function* (this: PageModel, slug: string, lang: string) {
    if (this.page[slug] && this.page[slug][lang]) return;
  
    this.state = 'Loading';
    try {
      const response = yield getPage(slug, lang);
  
      if (response && response.data) {
        if (!this.page[slug]) {
          this.page[slug] = {};
        }
        this.page[slug][lang] = response.data;
        this.state = 'Success';
      } else {
        throw new Error('Invalid API response');
      }
    } catch (error) {
      console.error('Error in getPage:', error);
      this.state = 'Error';
    }
  }).bind(this);
  

}

const PageStore = new PageModel();
export default PageStore;