import { AxiosError } from 'axios';
import { ToastStore } from '@stores';
import { API_PATHS } from './api';
import AuthStore from '../stores/AuthStore';

const errorHandler = (error: AxiosError) => {
  // TODO: lisää intl
  // if has response show the error
  let msg = '';
  let translate = false;

  if (error.response) {
    const { response: { status }, config: { url } } = error;

    const path = url?.replace(process.env.GATSBY_API_URL as string, '');

    switch (status) {
      case 401:
        if (path === API_PATHS.login) {
          msg = 'pages.login.wrongUsernamePassword';
          translate = true;
        }
        if (path === API_PATHS.activateUser) {
          msg = 'pages.register.activateError';
          translate = true;
        }
        break;
      case 400:
        msg = 'common.errors.400';
        translate = true;
        // Login fails
        if (path === API_PATHS.login) {
          msg = 'pages.login.wrongUsernamePassword';
          translate = true;
        }
        if (path === API_PATHS.changeEmail) {
          msg = 'pages.changeEmail.wrongPassword';
          translate = true;
        }

        break;
      case 403:
        msg = 'common.errors.403';
        AuthStore.logout();
        translate = true;
        break;
      case 408:
      case 409:
      case 500:
      case 404:
        // TODO: let's squelch these for awhile
        throw error;
        break;
      default:
        break;
    }
  }
  if (msg) ToastStore.showError(msg, translate);

  throw error;
};


export default errorHandler;
