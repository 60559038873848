export const PATHS = {
  index: '/',
  myInformation: {
    index: '/me/',
    paymentInformation: '/me/payment-information/',
    dwelling: '/me/dwelling/',
    residents: '/me/residents/',
    faultReports: '/me/fault-reports/',
    agreement: '/me/agreement/',
  },
  living: {
    index: '/living/',
    checklist: '/living/new-resident-checklist',
    faq: '/living/faq',
    customerBenefits: '/living/customer-benefits',
  },
  residentActivity: {
    index: '/resident-activity/',
    committee: '/resident-activity/resident-committee',
    tenantDemocracy: '/resident-activity/tenant-democracy',
  },
  news: '/news/',
  contacts: {
    index: '/contact/',
    customerService: '/contact/customer-service',
    maintenance: '/contact/maintenance',
    rentCollection: '/contact/rent',
  },
  privacyPolicy: '/privacy-policy/',
  changePassword: '/change-password/',
  changeEmail: '/change-email/',
  forms: {
    reserveParkingLot: '/forms/reserve-parking-lot',
    lostKeys: '/forms/lost-keys',
    faultReport: '/forms/fault-report',
  },
  auth: {
    logout: '/logout',
    login: '/login',
    register: '/register',
  },
  messages: {
    index: '/messages',
  }
};

const MAINTENANCE = { name: 'pages.contactInformation.maintenance', href: PATHS.contacts.maintenance };
const RENT_AND_COLLECTION = { name: 'pages.contactInformation.rentCollection', href: PATHS.contacts.rentCollection };
const CUSTOMER_SERVICE = { name: 'pages.contactInformation.customerService', href: PATHS.contacts.customerService };
export const NAV_ITEMS = [
  {
    name: 'pages.indexPage.indexPage',
    href: PATHS.index,
  },
  {
    name: 'pages.myInformation.myInformation',
    href: PATHS.myInformation.index,
    subPages: [{
      name: 'pages.myInformation.myInformation',
      href: PATHS.myInformation.index,
    }, {
      name: 'pages.myInformation.paymentInformation',
      href: PATHS.myInformation.paymentInformation,
    }, {
      name: 'pages.myInformation.dwellingProperty',
      href: PATHS.myInformation.dwelling,
    }, {
      name: 'pages.myInformation.residents',
      href: PATHS.myInformation.residents,
    }, {
      name: 'pages.myInformation.faultReports',
      href: PATHS.myInformation.faultReports,
    }, {
      name: 'pages.myInformation.agreement',
      href: PATHS.myInformation.agreement,
    }],
  },
  {
    name: 'pages.living.living',
    href: PATHS.living.index,
    subPages: [{
      name: 'pages.living.living',
      href: PATHS.living.index,
    }, {
      name: 'pages.living.checklist',
      href: PATHS.living.checklist,
    }, {
      name: 'common.faq',
      href: PATHS.living.faq,
    }, {
      name: 'pages.living.customerBenefits',
      href: PATHS.living.customerBenefits,
    }],
  },
  {
    name: 'pages.residentActivity.residentActivity',
    href: PATHS.residentActivity.index,
    subPages: [{
      name: 'pages.residentActivity.residentActivity',
      href: PATHS.residentActivity.index,
    }, {
      name: 'pages.residentActivity.residentCommittee',
      href: PATHS.residentActivity.committee,
    }, {
      name: 'pages.residentActivity.tenantDemocracy',
      href: PATHS.residentActivity.tenantDemocracy,
    }],
  },
  {
    name: 'pages.latest.latest',
    href: PATHS.news,
  },
  {
    name: 'pages.contactInformation.contactInformation',
    href: PATHS.contacts.index,
    subPages: [{
      name: 'pages.contactInformation.faq',
      href: PATHS.contacts.index,
    },
      CUSTOMER_SERVICE,
      MAINTENANCE,
      RENT_AND_COLLECTION,

    ]
  },
];

export const CONTACT_SHORTCUTS = [
  MAINTENANCE,
  RENT_AND_COLLECTION,
  {
    name: 'common.content.shortcutBox.makeFaultReport',
    href: PATHS.forms.faultReport,
  },
  CUSTOMER_SERVICE,
  {
    name: 'common.content.shortcutBox.orderKeys',
    href: PATHS.forms.lostKeys,
  },
  {
    name: 'pages.myInformation.reserveParkingSpace',
    href: PATHS.forms.reserveParkingLot,
  }
];
