import { v4 as uuid } from 'uuid';

type Type = 'error' | 'success' | 'info';

class Toast {
  msg: string;

  type: Type;

  index: number;

  id: string;

  translate?: boolean;

  constructor(msg: string, type: Type, index: number, translate?: boolean) {
    this.id = uuid();
    this.msg = msg;
    this.type = type;
    this.index = index;
    this.translate = translate;
  }
}

export default Toast;
