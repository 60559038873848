import React from 'react'
import { Provider } from 'mobx-react'
import PageStore from './src/stores/PageStore';

import {
  CommonDataStore,
  ToastStore,
  AuthStore,
  FaqStore,
  ChecklistStore,
  NewsStore,
  AgreementStore,
  PaymentStore,
  FaultReportStore,
  CurrentUserStore,
  MessageStore,
  EventStore,
} from './src/stores';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <div>
      <Provider
        commonDataStore={CommonDataStore}
        toastStore={ToastStore}
        authStore={AuthStore}
        faqStore={FaqStore}
        newsStore={NewsStore}
        checklistStore={ChecklistStore}
        agreementStore={AgreementStore}
        paymentStore={PaymentStore}
        faultReportStore={FaultReportStore}
        currentUserStore={CurrentUserStore}
        messageStore={MessageStore}
        pageStore={PageStore}
        eventStore={EventStore}
      >{element}</Provider>
    </div>
  );
};
