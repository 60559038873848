import { observable, flow, action } from 'mobx';
import {
  getAuthUrl, getValidatedUserInfo, createUser, login, changePassword,
  updatePassword,
  setWizardCompleted,
  getPasswordResetInfo // Assuming you added this service function
} from '../services/api';
import { ToastStore } from '@stores';
import { PATHS } from '../constants/nav-items';
import { navigate } from '@reach/router';
import CurrentUserStore from './CurrentUserStore';
import AgreementStore from './AgreementStore';
import MessageStore from './MessageStore';
import NewsStore from './NewsStore';
import PaymentStore from './PaymentStore';
import FaultReportStore from './FaultReportStore';

export const TOKEN = 'auth_token';
interface LoginObj {
  email: string;
  password: string;
}

export class AuthModel {
  @observable isWizardCompleted = true;

  @observable userCreated = false;

  @observable isAuthenticated? = undefined as boolean | null | undefined;

  @observable authUrl = "";

  @observable authUrlPwd = "";

  @observable state: StoreState = "Idle";

  @observable validatedUser = null;

  getAuthUrl = flow(function* (this: AuthModel, callbackUri: string) {

    if (this.authUrl === "") {
      const response = yield getAuthUrl(callbackUri);
      this.authUrl = response.data;
      return this.authUrl;
    }
    return Promise.resolve(this.authUrl);
  }).bind(this);

  getAuthUrlPwd = flow(function* (this: AuthModel, callbackUri: string) {

    if (this.authUrlPwd === "") {
      const response = yield getAuthUrl(callbackUri);
      this.authUrlPwd = response.data;
      return this.authUrlPwd;
    }
    return Promise.resolve(this.authUrlPwd);
  }).bind(this);

  @action
  resetStore() {
    this.userCreated = false;
    this.isAuthenticated = false;
    this.authUrl = "";
    this.authUrlPwd = "";
    this.state = "Idle";
    this.validatedUser = null;
  }

  getValidatedUserInfo = flow(function* (this: AuthModel, params: string) {
    this.state = "Loading";
    try {
      const response = yield getValidatedUserInfo(params);

      if (response.data && response.data) {
        // Case for validated-user registration flow
        this.validatedUser = response.data;
        return response.data;
      } else {

        throw new Error("Invalid response structure");
      }
    } catch (err) {
      // Redirect to the register page in case of error
      navigate(PATHS.auth.register);
      this.state = "Error";
      throw err;
    }
  }).bind(this);

  getPasswordResetInfo = flow(function* (this: AuthModel, params: string) {
    this.state = "Loading";
    try {
      const response = yield getPasswordResetInfo(params);

      if (response.data && response.data.passwordResetToken) {
        // Case for change-password flow
        this.validatedUser = response.data;
        return response.data;
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (err) {
      // Redirect to the change-password page in case of error
      navigate(PATHS.changePassword);
      this.state = "Error";
      throw err;
    }
  }).bind(this);

  createUser = flow(function* (
    this: AuthModel,
    data: LoginObj,
    submitCallback: (err: string) => void
  ) {
    this.state = "Loading";

    try {
      yield createUser(data);
      this.userCreated = true;
    } catch (err: any) {
      let msg = "pages.register.registrationFailed";
      if (err.response.status === 409) {
        msg = "pages.register.registrationFailedEmailExists";
      }
      this.state = "Error";
      this.userCreated = false;
      submitCallback(err);
      ToastStore.showError(msg, true);
      throw err;
    }
  }).bind(this);

  setWizardCompleted = flow(function* (this: AuthModel, email: string) {
    try {
      const res = yield setWizardCompleted(email)
      this.isWizardCompleted = true
      return res;
    } catch (err) {
      this.isWizardCompleted = false;
      throw err;
    }
  }).bind(this)

  login = flow(function* (this: AuthModel, formData: LoginObj, cb: Function) {
    try {
      const response = yield login(formData);
      if (response === undefined) return;
      if (response.data.user) {
        this.isWizardCompleted = response.data.user.wizardCompleted;
      }
      // SSR
      if (window !== undefined && window.localStorage && response) {
        // 30 days
        localStorage.setItem(
          "token_expires",
          JSON.stringify(
            new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).getTime()
          )
        );
        localStorage.setItem(TOKEN, response.data.access_token);
        this.refreshIsAuthenticated();
      }
      return response;
    } catch (error) {
      cb(error);
      throw error;
    }
  }).bind(this);

  logout = () => {
    if (window !== undefined && window.localStorage) {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem("token_expires");
      this.refreshIsAuthenticated();
    }
    AgreementStore.reset();
    CurrentUserStore.resetStore();
    MessageStore.resetStore();
    NewsStore.resetStore();
    this.resetStore();
    PaymentStore.reset();
    FaultReportStore.resetStore();
  };

  @action refreshIsAuthenticated = () => {
    if (typeof window !== "undefined") {
      this.isAuthenticated = !!localStorage.getItem(TOKEN);
      return;
    }
    this.isAuthenticated = false;
  };

  changePassword = flow(function* (this: AuthModel, email, cb) {
    try {
      yield changePassword(email);
      ToastStore.showSuccess(
        "pages.changePassword.changePasswordSuccess",
        true
      );
      cb();
      this.state = "Success";
      const timeout = setTimeout(() => {
        this.logout();
        navigate("/");
        clearTimeout(timeout);
      }, 3000);
    } catch (error) {
      ToastStore.showError("pages.changePassword.changePasswordFailed", true);
      this.state = "Error";
      cb();
      throw error;
    }
  }).bind(this);

  updatePassword = flow(function* (
    this: AuthModel,
    passwordResetToken,
    password,
    cb
  ) {

    console.log('updatePassword()')

    try {
      yield updatePassword({
        passwordResetToken,
        password,
      });
      ToastStore.showSuccess(
        "pages.changePassword.updatePasswordSuccess",
        true
      );
      cb();
      this.state = "Success";
    } catch (error) {
      console.error(error);
      ToastStore.showError("pages.changePassword.updatePasswordFailed", true);
      this.state = "Error";
      cb();
      throw error;
    }
  }).bind(this);

}

const AuthStore = new AuthModel();

export default AuthStore;
