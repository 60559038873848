import { toJS, autorun, set } from 'mobx';
import store from 'store';

export const storeAutoSave = (_this: any, storeName: string, omittedProps?: string[]) => {
  let firstRun = true;

  // will run on change
  autorun(() => {
    // on load check if there's an existing store on localStorage and extend the store
    if (firstRun) {
      const existingStore = store.get(storeName);

      if (existingStore) {
        set(_this, existingStore);
      }
    }

    // from then on serialize and save to localStorage
    const storeToSave = toJS(_this);
    if (omittedProps) {
      omittedProps?.forEach((prop) => {
        delete storeToSave[prop];
      });
    }
    store.set(storeName, storeToSave);
  });

  firstRun = false;
};
