/* eslint-disable prefer-destructuring */
import {
  observable, flow, computed,
} from 'mobx';
import { fetchPayments, fetchCharges, fetchDeposits, fetchRightOfOccupancyPayments } from '../services/api';
import AgreementStore from './AgreementStore';

class PaymentModel {
  @observable payments: IPaymentStore['payments'] = [];

  @observable deposits: IPaymentStore['deposits'] = [];

  @observable charges: IPaymentStore['charges'] = [];

  @observable rightOfOccupancyPayments: IPaymentStore['rightOfOccupancyPayments'] = [];

  @observable state: StoreState = 'Idle';

  reset = () => {
    this.state = 'Idle';
    this.payments = [];
    this.charges = [];
    this.deposits = [];
    this.rightOfOccupancyPayments = [];
  }


  @computed
  public get currentDeposits() {
    const deposits = this.deposits.find((deposit) => deposit.agreement_id === AgreementStore.selectedAgreementId)?.deposits;
    return deposits;
  }


  @computed
  public get currentCharges() {
    const charges = this.charges.find((charge) => charge.agreement_id === AgreementStore.selectedAgreementId)?.charges;
    return charges;
  }

  @computed
  public get currentPayments() {
    const payments = this.payments.find((payment) => payment.agreement_id === AgreementStore.selectedAgreementId)?.payments;
    return payments;
  }

  @computed
  public get currentRightOfOccupancyPayments() {
    const rightOfOccupancyPayments = this.rightOfOccupancyPayments.find((rooPayment) => rooPayment.agreement_id === AgreementStore.selectedAgreementId)?.rightOfOccupancyPayments;
    return rightOfOccupancyPayments;
  }

  getPaymentsPageData = flow(function* (this: PaymentModel, agreementId: number) {
    yield this.getPayments(agreementId);
    yield this.getDeposits(agreementId);
    yield this.getCharges(agreementId);
    yield this.getRightOfOccupancyPayments(agreementId);
  }).bind(this);

  getPayments = flow(function* (this: PaymentModel, agreementId: number) {
    this.state = 'Loading';

    try {
      const response = yield fetchPayments(agreementId!);
      this.payments.push({ agreement_id: agreementId, payments: response.data.payments })
      this.state = 'Success';
    } catch (error) {
      this.payments = [];
      this.state = 'Error';
    }
  }).bind(this);

  getDeposits = flow(function* (this: PaymentModel, agreementId: number) {
    if (this.deposits.some((obj) => obj.agreement_id === agreementId)) return;
    this.state = 'Loading';

    try {
      const response = yield fetchDeposits(agreementId);

      this.deposits.push({ agreement_id: agreementId, deposits: response.data.deposits })

      this.state = 'Success';
    } catch (error) {
      this.deposits = [];
      this.state = 'Error';
    }
  }).bind(this);

  getCharges = flow(function* (this: PaymentModel, agreementId) {
    this.state = 'Loading';

    try {
      const response = yield fetchCharges(agreementId);
      this.charges.push({ agreement_id: agreementId, charges: response.data.charges });
      this.state = 'Success';
    } catch (error) {
      this.charges = [];
      this.state = 'Error';
    }
  }).bind(this);

  getRightOfOccupancyPayments = flow(function* (this: PaymentModel, agreementId: number) {
    this.state = 'Loading';

    try {
      const response = yield fetchRightOfOccupancyPayments(agreementId);
      this.rightOfOccupancyPayments.push({ agreement_id: agreementId, rightOfOccupancyPayments: response.data });

      this.state = 'Success';
    } catch (error) {
      this.rightOfOccupancyPayments = [];
      this.state = 'Error';
    }
  }).bind(this);
}

const PaymentStore = new PaymentModel();
export default PaymentStore;
